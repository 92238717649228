
import { Actions } from '@/store/modules/ThirdPartyModule';
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat } from '@/utils/helper/format';
import { useStore } from 'vuex';

const breadcrumbs = ['Promotions Banner', ['List']];

export default {
  name: 'Commission List',
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableRef = ref<any>(null);
    const state = reactive({
      tableData: [] as any,
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
      type: 'Fix',
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        state.loading = true;

        const { datas, total } = await store.dispatch(
          Actions.FETCH_THIRD_PARTY,
          {
            page,
            pageSize: rowsPerPage,
          }
        );

        state.tableData = datas;
        state.total = total;

        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
    });

    return {
      ...toRefs(state),
      tableRef,
      breadcrumbs,
      dateFormat,
      tableHeader: computed(() => {
        return [
          {
            key: 'name',
            name: 'Name',
            sortable: true,
          },
          {
            key: 'isActive',
            name: 'Status',
            sortable: false,
          },
          {
            key: 'editView',
            name: 'Edit/View',
            sortable: false,
          },
        ];
      }),
    };
  },
};
